import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IStaffBankMembershipEntity } from '../../interfaces/api/staff-bank-membership-entity';
import { paginationAdapter } from './staff-bank-membership.adapter';

export const StaffBankMembershipPaginationMessages = paginationAdapter.getMessages();

export class UpsertStaffBankMembershipPageMessage extends StaffBankMembershipPaginationMessages.UpsertPageMessage {}
export class UpsertStaffBankMembershipMultiplePagesMessage extends StaffBankMembershipPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetStaffBankMembershipPaginationMessage extends StaffBankMembershipPaginationMessages.ResetPaginationMessage {}

export enum StaffBankMembershipSignalTypes {
  LOAD_STAFF_BANK_MEMBERSHIPS = '[StaffBankMembership][Signal] Load Collection',
}
export enum StaffBankMembershipMessageTypes {
  SET_COLLECTION = '[StaffBankMembership][Message] Set Collection',
  ADD_ONE = '[StaffBankMembership][Message] Add One',
  UPDATE_ONE = '[StaffBankMembership][Message] Update One',
  UPSERT_ONE = '[StaffBankMembership][Message] Upsert One',
  DELETE_ONE = '[StaffBankMembership][Message] Delete One',
  ADD_MULTIPLE = '[StaffBankMembership][Message] Add All',
  DELETE_MULTIPLE = '[StaffBankMembership][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffBankMembership][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffBankMembership][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IStaffBankMembershipEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IStaffBankMembershipEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IStaffBankMembershipEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IStaffBankMembershipEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IStaffBankMembershipEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IStaffBankMembershipEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IStaffBankMembershipEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = StaffBankMembershipMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export type StaffBankMembershipMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
